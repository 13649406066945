/*
        MENU
	99Lime.com HTML KickStart by Joshua Gatcke
	kickstart.js
*/
jQuery(document).ready(function($){
    
    $("[class^='m_crt_-_']").html(function(){
    var str = $(this).attr('class');
    var part = str.split('_-_');
    var h = part[1]+'&#64;'+part[2]+'.'+part[3];
    var r = '<a href=\"mailto:'+h+'\">'+h+'</a>';
    return r;
    
    });
    $('ul.menu').each(function(){

            // find menu items with children.
            $(this).find('li').has('ul').addClass('has-menu')
            .find('a:first').append('<span class="arrow">&nbsp;</span>');
    });

    $('ul.menu li').hover(function(){
            $(this).find('ul:first').stop(true, true).fadeIn('fast');
            $(this).addClass('hover');
    },
    function(){
            $(this).find('ul').stop(true, true).fadeOut('slow');
            $(this).removeClass('hover');
    });
        //Tooltip     
    $(function(){
        $(".ac-tooltip").click(function(event) {
            event.preventDefault();
        });
        $('.ac-tooltip').hover(function(e){ // Hover event
            var titleText = $(this).attr('title');
            $(this)
            .data('tiptext', titleText)
            .removeAttr('title');

        $('<p class="tooltip"></p>')
            .text(titleText)
            .appendTo('body')
            .css('top', (e.pageY - 10) + 'px')
            .css('left', (e.pageX + 20) + 'px')
            .fadeIn('slow');

        }, function(){ // Hover off event

            $(this).attr('title', $(this).data('tiptext'));
            $('.tooltip').remove();

        }).mousemove(function(e){ // Mouse move event

            $('.tooltip')
            .css('top', (e.pageY - 10) + 'px')
            .css('left', (e.pageX + 20) + 'px');

        });

    });
});



